@charset "UTF-8";
/* RESET */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

:focus {
  outline: 0; }

ins {
  text-decoration: none; }

del {
  text-decoration: line-through; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/*	--------------------------------------------------
Global Reset - Resets all browser defaults to 0
-------------------------------------------------- */
body {
  background: #4f4f4f url(1-default/bodybg.png) repeat-x left top;
  color: #444; }

#outer {
  background: url(1-default/pattern.png) repeat left top; }

.posts h2 a, .posts p a:hover, a {
  color: #000; }

.more a {
  font-size: 12px; }

p {
  margin: 0;
  padding: 0; }

a:hover, .posts h2 a:hover, .posts p a {
  color: #b90202; }

hr {
  color: #fffdea;
  border: none;
  border-top: 1px dotted #372322; }

.hr2 {
  color: #fffdea;
  border: none; }

/* headings ---------------------- */
h1, h2, h3, h4, h5, h6 {
  color: #000; }

/* Structure ---------------------- */
#wrapper {
  width: 938px;
  margin: 0 auto;
  padding: 0 20px;
  background: #fff; }

#banner {
  background: #000; }

#footer {
  background: #f7f7f7;
  border-top: 3px double #dedede; }

#page_nav {
  background: #fff url(1-default/pagenavbg.png) repeat-x left bottom; }

#categories_strip {
  background: #939393 url(1-default/navbg.png) repeat-x left bottom; }

.content_sub_sidebar_right {
  border-left: 1px solid #e2e2e2;
  border-right: 1px solid #e2e2e2; }

#content h1.cat_head {
  color: #970101;
  border-bottom: 1px solid #e5e5e5; }

#content h1.single_head {
  color: #970101;
  border-bottom: 1px solid #e5e5e5; }

.callnow {
  background: url(1-default/callnow.png) no-repeat left top; }

.callnow span {
  color: #a8a8a8; }

#page_nav p.links a.rss {
  background: url(../images/i_rss2.png) no-repeat 2px top; }

#page_nav p.links a.email {
  background: url(../images/i_email.png) no-repeat left top; }

/* header ---------------------- */
#header .blog-title a {
  color: #000; }

#header .blog-title a:hover {
  color: #b90202; }

#header .blog-description {
  color: #6c6c6c; }

/*---------TOP NAVIGATION for PAGES---------  */
#page_nav p.links {
  color: #7d7d7d; }

#page_nav p.links a {
  color: #7d7d7d; }

#page_nav p.links a:hover {
  color: #000; }

/* ----searchform ------ */
.searchform {
  background: #e5e5e5; }

.searchform .s {
  color: #b2b2b2;
  background: #fff;
  border: 1px solid #ccc; }

.searchform .s:focus {
  color: #333; }

.search_btn {
  background: url(1-default/b_search.png) no-repeat left top; }

/*#breadcrumb section  =================================== */
.breadcrumb {
  border-bottom: 1px dotted #dfdfdf;
  background: #fff;
  color: #777; }

.breadcrumb a {
  color: #c63133; }

.breadcrumb a:hover {
  color: #000; }

/* content ---------------------------- */
#content li {
  background: url(1-default/arrow_cat.png) no-repeat left 2px; }

#content ol li {
  background: none; }

/* car details ----------------------------------------------------- */
a.b_print {
  color: #535353;
  background: url(1-default/b_print.png) no-repeat left top;
  border: 1px solid #dfdfdf;
  -moz-box-shadow: 1px 1px 1px #ddd;
  -webkit-box-shadow: 1px 1px 1px #ddd; }

a.b_download_pdf {
  color: #535353;
  background: url(1-default/b_download_pdf.png) no-repeat left top;
  border: 1px solid #dfdfdf;
  -moz-box-shadow: 1px 1px 1px #ddd;
  -webkit-box-shadow: 1px 1px 1px #ddd; }

a.b_sendtofriend {
  color: #535353;
  background: url(1-default/b_sendtofriend.png) no-repeat left top;
  border: 1px solid #dfdfdf;
  -moz-box-shadow: 1px 1px 1px #ddd;
  -webkit-box-shadow: 1px 1px 1px #ddd; }

.likethis {
  border-bottom: 1px solid #eee; }

/* gallery - detail page slideshow -------------------*/
.img_not_available_single {
  border: 1px solid #ddd;
  color: #bbb; }

.galleria-thumbnails .galleria-image {
  background: #fff;
  border: 1px solid #ccc;
  border-bottom: 2px solid #fff; }

.galleria-counter {
  color: #fff; }

.galleria-loader {
  background: #000;
  background: url(../images/classic-loader.gif) no-repeat 2px 2px; }

.galleria-info-text {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.galleria-info-title {
  color: #fff; }

.galleria-info-description {
  color: #bbb; }

.galleria-info-close {
  background-position: -753px -11px; }

.galleria-info-link {
  background-position: -669px -5px;
  background-color: #000; }

.galleria-image-nav-right {
  background-position: -300px 0; }

.galleria-thumb-nav-left,
.galleria-thumb-nav-right {
  background-position: -495px 11px; }

.galleria-thumb-nav-right {
  background-position: -570px 11px;
  border-right: none; }

.galleria-thumb-nav-left,
.galleria-thumb-nav-right,
.galleria-info-link,
.galleria-info-close,
.galleria-image-nav-left,
.galleria-image-nav-right {
  background-image: url(../images/classic-map.png);
  background-repeat: no-repeat; }

#content .vehicle_summary p {
  color: #4f4f4f; }

#content .vehicle_summary p span {
  color: #000; }

#content .vehicle_summary p.price {
  color: #970101; }

#content .vehicle_summary h4 {
  border-bottom: 1px solid #ddd;
  color: #b10000; }

#content .vehicle_video h4 {
  border-bottom: 1px solid #ddd;
  color: #b10000; }

a.b_send_inquiry {
  color: #fff;
  text-shadow: 1px 1px 1px #333;
  background: url(1-default/b_sendinquiry.png) no-repeat left top; }

a:hover.b_send_inquiry {
  color: #fff;
  background-position: 0 -43px; }

/* contact us page ----------- */
#content .form_row .textfield {
  border: 1px solid #ddd;
  border-right: 2px solid #ccc;
  border-bottom: 2px solid #ccc;
  background: #fff;
  color: #666; }

#content .form_row .textarea {
  border: 1px solid #ddd;
  background: #fff;
  border-right: 2px solid #ccc;
  border-bottom: 2px solid #ccc;
  color: #666; }

#content .error, .message_error2 {
  color: #C00; }

#contact_frm .b_submit {
  border: 3px double #fff;
  background: #666;
  color: #fff; }

#contact_frm .b_submit:hover {
  background: #000; }

#content p.success_msg {
  color: #356c1d;
  background: #cee8c3;
  border-top: 1px solid #7ea96b;
  border-bottom: 1px solid #7ea96b; }

.indicates {
  color: #C00; }

/* content front widget -------------------- */
#content .widget h3 {
  background: #e7e6e6 url(1-default/content_titlebg.png) repeat-x left bottom;
  border-bottom: 1px solid #d8d8d8; }

#content .widget h3 a.more {
  position: absolute;
  right: 12px;
  top: 18px;
  padding-right: 10px;
  font: 10px Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  color: #870000;
  background: url(1-default/bullet2.png) no-repeat right 2px; }

#content .widget h3 a:hover.more {
  color: #000; }

/* cars manufactures ---------------------- */
#content .manufactures_by li {
  color: #a4a4a4;
  background: url(1-default/bullet.png) no-repeat left 12px;
  border-bottom: 1px solid #ddd; }

#content .manufactures_by li a {
  color: #787878; }

#content .manufactures_by li a:hover {
  color: #ab010b; }

/* sort by ----------------- */
.sort_by select {
  border: 1px solid #ddd; }

.sort_by a.previous {
  background: #e7e7e7 url(1-default/pprevious.png) no-repeat 5px 7px;
  color: #000;
  border-right: 2px solid #fff; }

.sort_by a:hover.previous {
  color: #ab010b; }

.sort_by a.next {
  background: #e7e7e7 url(1-default/pnext.png) no-repeat 34px 7px;
  border-left: 2px solid #fff;
  color: #000; }

.sort_by a:hover.next {
  color: #ab010b; }

/* new car deals -----------------------*/
#content .newcardeals li {
  background: url(1-default/sepretor.png) no-repeat center bottom; }

#content .newcardeals li .img_not_available {
  border: 1px solid #ddd;
  color: #656565; }

#content .newcardeals li a.post_img {
  border: 1px solid #ddd; }

#content .newcardeals li h3 {
  background: none;
  border-bottom: 1px solid #ddd; }

#content .newcardeals li h3 a {
  color: #ab010b; }

#content .newcardeals li h3 a:hover {
  color: #000; }

#content .newcardeals li h3 span {
  color: #ab010b; }

#content .newcardeals li p {
  color: #656565;
  background: none;
  border: none; }

#content .newcardeals li p.highlight_text {
  color: #000; }

#content .newcardeals li a.b_viewdetails {
  color: #fff;
  background: url(1-default/b_viewdetails.png) no-repeat left top; }

#content .newcardeals li a:hover.b_viewdetails {
  background-position: 0 -27px;
  color: #fff; }

/* slider --------------------------- */
#slider {
  border-left: 1px solid #906b6b; }

.nivoSlider a.nivo-imageLink {
  border: 0; }

.nivo-caption {
  background: #000;
  color: #fff; }

.nivo-prevNav {
  background: url(../images/previous.png) no-repeat left top; }

.nivo-prevNav:hover {
  background: url(../images/previous.png) no-repeat left top;
  color: #fff; }

.nivo-nextNav {
  background: url(images/next.png) no-repeat left top; }

.nivo-nextNav:hover {
  background: url(../images/next.png) no-repeat left top; }

.nivo-controlNav a {
  color: #fff; }

/* banner nav ---- */
.nivo-controlNav a {
  background: url(1-default/dot_n.png) no-repeat;
  border: none;
  color: #fff; }

.nivo-controlNav a:hover, .nivo-controlNav a.active {
  background: url(1-default/dot_h.png) no-repeat; }

/* post ---------------------------- */
.comment_post a {
  background: url(1-default/comment_bg.png) no-repeat; }

.posts {
  border-bottom: 1px solid #ddd; }

.main_img {
  border: 1px solid #ccc;
  background: #fff; }

.date {
  color: #858585; }

.by {
  color: #858585;
  border-bottom: 1px solid #e3e3e3; }

#content .posts p.post_bottom .category {
  color: #858585;
  background: url(../images/i_category.png) no-repeat left 0px; }

.tags {
  color: #858585;
  background: url(../images/i_tags.png) no-repeat left 2px; }

.post_left a {
  color: #ab010b;
  background: url(1-default/pprevious.png) no-repeat left 16px; }

.post_left a:hover {
  color: #000; }

.post_right a {
  color: #ab010b;
  background: url(1-default/pnext.png) no-repeat right 16px; }

.post_right a:hover {
  color: #000; }

.b_text {
  font: bold 12px Arial, Helvetica, sans-serif;
  color: #858585; }

.bookmark {
  border-top: 2px solid #ebebeb; }

.video_main {
  border: 2px solid #ccc; }

code {
  background: #ccc; }

blockquote {
  background: url(1-default/quote.png) no-repeat; }

blockquote p {
  color: #333; }

blockquote cite {
  color: #666; }

blockquote a {
  color: #333; }

blockquote a:hover {
  color: #000; }

.flickr img {
  border: 1px solid #ddd;
  background: #fff; }

.flickr img:hover {
  border-color: #333; }

#content .posts p.date {
  color: #a9a9a9; }

#content .posts p.date a {
  color: #444;
  border-bottom: 1px dotted #ccc; }

#content .posts p.date a:hover {
  color: #000; }

/* Post Pagination */
.pagination .on {
  background: #810000 url(1-default/page_nav_h.png) repeat left top;
  color: #fff; }

.pagination a {
  background: #333 url(1-default/page_nav_n.png) repeat left top;
  color: #fff; }

.pagination a:hover {
  background: #810000 url(1-default/page_nav_h.png) repeat left top;
  color: #fff; }

.pos_navigation {
  background: url(1-default/post_navi_sepretor.png) repeat-x left bottom; }

.i_print {
  background: url(../images/i_print.png) no-repeat left top; }

\
.video_main {
  border: 2px solid #ccc; }

/* archive page list----------------------- */
#content .news_list li {
  background: url(1-default/sepretor.png) no-repeat center bottom;
  position: relative; }

#content .news_list li h3 {
  background: none;
  color: #0d428b; }

#content .news_list li h3 a {
  color: #ab010b; }

#content .news_list li h3 a:hover {
  color: #000; }

#content .news_list li p.date {
  color: #ccc; }

#content .news_list li p.postedby {
  color: #929292; }

#content .news_list li p.postedby .pcomments {
  background: url(1-default/i_comments.png) no-repeat right 4px; }

#content .news_list li p.postedby a.pcomments {
  color: #8a8a8a; }

#content .news_list li p.postedby a {
  color: #4f4f4f; }

#content .news_list li p.postedby a:hover, #content .news_list li p.postedby a:hover.pcomments {
  color: #000; }

#content .news_list li img {
  float: left;
  margin: 5px 15px 0 0;
  padding: 5px;
  border: 1px solid #c9c9c9; }

a.more {
  color: #494949;
  background: #e7e5e5 url(1-default/bullet.png) no-repeat 74px 10px; }

a:hover.more {
  color: #ab010b; }

/* related post ----------------------- */
#content .category_newslist li {
  background: url(1-default/hr.png) repeat-y 18px top; }

#content .category_newslist li h3 {
  background: none;
  color: #0d428b;
  border: none; }

#content .category_newslist li h3 a {
  color: #ab010b; }

#content .category_newslist li h3 a:hover {
  color: #333; }

#content .category_newslist li p.date {
  color: #ccc; }

#content .category_newslist li img {
  border: 1px solid #c9c9c9; }

#content .category_newslist li p.date .pcomments {
  background: url(1-default/i_comments.png) no-repeat right 4px; }

#content .category_newslist li p.date a.pcomments {
  color: #8a8a8a; }

#content .category_newslist li p.date a:hover.pcomments {
  color: #000; }

#content .category_newslist li.hr {
  background: url(1-default/hr.png) repeat-x center; }

/* featured video */
#sidebar .featured_video {
  color: #d64046;
  border: 1px solid #d0d0d0; }

#sidebar .featured_video h4 a {
  color: #0d428b; }

.advt4widget {
  border: 1px solid #d0d0d0; }

/*---------Top Pages Navigation for PAGES---------  */
#page_nav ul li ul li {
  /* border-style: solid; border-width: 1px; border-color: #ccc #ccc #ccc;*/ }

#page_nav ul li ul li.hover, #page_nav ul li ul li:hover, #page_nav ul li ul li.current-cat:hover, ul.dropdown li ul li.current_page_parent:hover, ul.dropdown li ul li.current_page_item:hover {
  color: #333;
  background: #a0a0a0; }

#page_nav ul li a {
  color: #909090;
  background: url(1-default/page_nav_sepretor.png) no-repeat left top; }

#page_nav ul li a:hover, #page_nav li:hover a {
  color: #fff;
  background: #a0a0a0; }

#page_nav li:hover a {
  color: #fff;
  background: #a0a0a0; }

#page_nav li:hover ul li a {
  color: #fff;
  background: #a0a0a0;
  border-left: none;
  border-right: none; }

#page_nav ul li.current_page_item a,
#page_nav ul li.current_page_parent a,
#page_nav ul li.current-menu-item a {
  background: #6d6d6d;
  color: #fff; }

#page_nav ul li.current_page_item ul li a,
#page_nav ul li.current_page_parent ul li a,
#page_nav ul li ul li a {
  background: #a0a0a0;
  color: #fff;
  border-bottom: 1px solid #c6c6c6; }

#page_nav ul li.current_page_item ul li a:hover,
#page_nav ul li.current_page_parent ul li.current_page_item a,
#page_nav ul li.current_page_parent ul li a:hover,
#page_nav ul li ul li a:hover,
#page_nav ul li ul.sub-menu li a,
#page_nav ul li ul.sub-menu ul.sub-menu li a, #page_nav ul li.current_page_parent ul li.current_page_item ul li a:hover {
  background: #6d6d6d;
  color: #fff; }

#page_nav ul li ul li.current_page_parent a {
  background: #6d6d6d; }

#page_nav ul li ul li.current_page_parent ul li.current_page_item a {
  background: #a0a0a0 !important; }

#page_nav ul li ul li.current_page_parent ul li a, #page_nav ul li ul li.current_page_item ul li a {
  background: #a0a0a0 !important; }

#page_nav ul li ul li.current_page_item ul li ul li a:hover {
  background: #6d6d6d !important; }

#page_nav ul li ul.sub-menu li a:hover,
#page_nav ul li ul.sub-menu li.current-menu-item a,
#page_nav ul li ul.sub-menu ul.sub-menu li a:hover,
#page_nav ul li ul.children li.current_page_parent li a {
  background: #6d6d6d;
  color: #fff; }

#page_nav ul ul {
  width: 190px;
  margin-top: 0px;
  border-right: 1px solid #c6c6c6;
  border-left: 1px solid #c6c6c6; }

/*---------Category Navigation for PAGES---------  */
#categories_strip ul li ul li {
  /* border-style: solid; border-width: 1px; border-color: #ccc #ccc #ccc;*/
  background: #5f0000; }

#categories_strip ul li ul li.hover, #categories_strip ul li ul li:hover, #categories_strip ul li ul li.current-cat:hover, ul.dropdown li ul li.current-cat-parent:hover, ul.dropdown li ul li.current-cat:hover {
  color: #fff;
  background: #960000; }

#categories_strip ul li a {
  color: #fff;
  background: url(1-default/nav_sepretor.png) no-repeat right top;
  border-left: none;
  border-right: none; }

#categories_strip ul li a:hover, #categories_strip li:hover a {
  color: #fff;
  background: #960000 url(1-default/current_nav.png) no-repeat right bottom; }

#categories_strip li:hover a {
  color: #fff;
  background: #960000 url(1-default/current_nav.png) no-repeat right bottom;
  text-shadow: none; }

#categories_strip li:hover ul li a {
  color: #ffa9a9;
  background: #960000;
  border-left: none;
  border-right: none; }

#categories_strip ul li.current-cat a,
#categories_strip ul li.current-cat-parent a,
#categories_strip ul li.current-menu-item a, #categories_strip ul li.current_page_item a {
  background: #960000 url(1-default/current_nav.png) no-repeat right bottom;
  color: #fff; }

#categories_strip ul li.current-cat ul li a,
#categories_strip ul li.current-cat-parent ul li a,
#categories_strip ul li ul li a {
  background: #960000;
  color: #fff;
  border-bottom: 1px solid #5f0000; }

#categories_strip ul li.current-cat ul li a:hover,
#categories_strip ul li.current-cat-parent ul li.current-cat a,
#categories_strip ul li.current-cat-parent ul li a:hover,
#categories_strip ul li ul li a:hover,
#categories_strip ul li ul.sub-menu li a,
#categories_strip ul li ul.sub-menu ul.sub-menu li a, #categories_strip ul li.current-cat-parent ul li.current-cat ul li a:hover {
  background: #730000;
  color: #fff; }

#categories_strip ul li ul li.current-cat-parent a {
  background: #960000; }

#categories_strip ul li ul li.current-cat-parent ul li.current-cat a {
  background: #960000 !important; }

#categories_strip ul li ul li.current-cat-parent ul li a, #categories_strip ul li ul li.current-cat ul li a {
  background: #960000 !important; }

#categories_strip ul li ul li.current-cat ul li ul li a:hover {
  background: #071d3a !important; }

#categories_strip ul li ul.sub-menu li a:hover,
#categories_strip ul li ul.sub-menu li.current-menu-item a,
#categories_strip ul li ul.sub-menu ul.sub-menu li a:hover,
#categories_strip ul li ul.children li.current-cat-parent li a {
  background: #960000;
  color: #fff; }

#categories_strip ul ul {
  width: 190px;
  margin-top: 0px;
  border-right: 1px solid #5f0000;
  border-left: 1px solid #5f0000; }

/*---------ARCHIVE PAGE --------- */
.arclist ul li {
  color: silver;
  border-bottom: 1px solid #f1f1ed;
  background: none; }

.arclist ul li ul {
  border-bottom: 0; }

.arclist ul li ul li {
  border-bottom: 0; }

.arclist .archives-time {
  color: #666666; }

/*---------COMMENTS --------- */
#comments .comment {
  background: none; }

#comments p.authorcomment {
  border-bottom: 1px dotted #ccc; }

#comments p.authorcomment a {
  color: #666; }

#comments p.authorcomment a:hover {
  color: #000; }

#comments .comment .meta-left {
  color: #aaaaaa; }

#comments .comment .authcomment p {
  color: #555; }

#comments .comment .text-right p a {
  color: #B90202;
  text-decoration: none; }

#comments .comment .text-right p a:hover {
  color: #000; }

span.comm-reply {
  color: #333;
  text-transform: uppercase; }

span.comm-reply a {
  color: #666; }

span.comm-reply a:hover {
  color: #b10000; }

.commpadd input {
  background: url(1-default/textfieldbg.png) no-repeat left top;
  border: none; }

#respond textarea {
  background: url(1-default/textareabg.png) no-repeat left top;
  border: none; }

input#submit {
  color: #fff;
  text-shadow: 1px 1px 1px #333;
  background: #AE0000;
  border: none; }

input#submit:hover {
  background: #000;
  border: none; }

.avatar {
  background: #ddd; }

#comments .comment a.comment-reply-link {
  background: #b10000 url(1-default/reply.png) no-repeat 42px;
  color: #fff; }

#comments .comment a:hover.comment-reply-link {
  color: #fff;
  background: #000 url(1-default/reply.png) no-repeat 42px; }

#respond .author {
  background: url(1-default/i_user.png) no-repeat left top; }

#respond .email {
  background: url(1-default/i_email_c.png) no-repeat left top; }

#respond .website {
  background: url(1-default/i_website.png) no-repeat left top; }

#respond .icomment {
  background: url(1-default/i_comment.png) no-repeat left top; }

#respond .comment_arrow {
  width: 14px;
  height: 17px;
  background: url(1-default/comment_arrow.png) no-repeat left top;
  display: block;
  position: absolute;
  left: 36px;
  top: 8px; }

/*#sidebar ----------------------- */
.sidebar .search_sidebar h3 {
  color: #fff;
  background: #970101 url(1-default/search_titlebg.png) repeat-x left bottom;
  text-shadow: 1px 1px 1px #333333; }

.sidebar .search_sidebar {
  background: #b10000 url(1-default/search_bottombg.png) no-repeat left bottom; }

.sidebar .search_sidebar select {
  background: #fff;
  border: none;
  color: #393939; }

.b_searchcar {
  color: #fff;
  text-shadow: 1px 1px 1px #333;
  background: url(1-default/b_searchcar.png) no-repeat left top;
  border: none; }

.b_searchcar:hover {
  background: url(1-default/b_searchcar.png) no-repeat 0 -45px; }

/* widget ------ */
.sidebar .widget {
  border: 1px solid #dfdfdf;
  background: #fff url(1-default/widgetbg.png) repeat-x left bottom; }

.sidebar .widget h3 {
  color: #fff;
  text-shadow: 1px 1px 1px #333;
  background: #959595 url(1-default/sidebar_titlebg.png) repeat-x left top; }

.sidebar .widget li a {
  color: #626262;
  background: url(1-default/bullet.png) no-repeat 10px 10px;
  border-bottom: 1px solid #dfdfdf; }

.sidebar .widget li a:hover {
  color: #ab010b; }

.sidebar .widget ul li:last-child a {
  border-bottom: none; }

.sidebar #recentcomments li a {
  background: none;
  border-bottom: none;
  color: #626262; }

.sidebar #recentcomments li {
  border-bottom: 1px solid #dfdfdf;
  background: url(1-default/bullet.png) no-repeat 0px 8px; }

.sidebar .widget p {
  color: #444; }

.sidebar #twitter_update_list li {
  background: url(1-default/bullet.png) no-repeat 10px 3px; }

.sidebar #twitter_update_list li a {
  border-bottom: none;
  background: none;
  color: #000; }

.sidebar #twitter_update_list li a:hover {
  color: #ab010b; }

.sidebar #twitter_update_list li, .sidebar .popular_post li {
  border-bottom: 1px solid #e5e5e5;
  color: #555; }

.sidebar #twitter_update_list li:last-child, .sidebar .popular_post li:last-child {
  border-bottom: none; }

.sidebar #twitter p {
  color: #626262;
  background: url(1-default/i_twitter.png) no-repeat 90px top; }

.sidebar #twitter p a {
  color: #ae0000; }

.sidebar #twitter p a:hover {
  color: #000; }

.subscribe_form {
  background: url(1-default/subscribebg.png) no-repeat left 1px; }

.subscribe_form .field {
  background: none;
  border: none;
  font: 13px Arial, Helvetica, sans-serif;
  color: #626262; }

.subscribe_form .btn_submit {
  border: none;
  background: url(1-default/i_email.png) no-repeat left top; }

.bookmark {
  border: 1px solid #dfdfdf;
  background: url(1-default/widgetbg.png) repeat-x left bottom; }

/* footer ---------------------------- */
#footer p {
  color: #9e9e9e; }

#footer p span.templatic {
  color: #fff;
  background: url(../images/templatic.png) no-repeat 0; }

#footer a {
  color: #ccc;
  text-decoration: underline; }

#footer a:hover {
  color: #fff; }

#footer p {
  color: #929292; }

#footer .copyright, #footer .copyright a {
  color: #929292; }

#footer p a:hover {
  color: #fff; }

/*

Theme Name: VanDerNorth.net - Automobile
Theme URI: http://www.vandernorth.net
Description:   Thema voor AutoBeheer
Version:       1.2.0
Author: VanDerNorth.net
Author URI: http://www.vandernorth.net

*/
/*             IMPORTANT NOTE!
               If you wish to make custom changes to your theme, DO NOT EDIT THIS FILE. Instead, use the custom.css template
               to define custom styles. This way, you will still be able to take advantage of future upgrades to the theme
               without worrying about overwriting your style changes.
*/
/*	--------------------------------------------------
Global Reset - Resets all browser defaults to 0
-------------------------------------------------- */
html, body {
  margin: 0;
  padding: 0; }

body {
  font: 14px Arial, Helvetica, sans-serif; }

a {
  text-decoration: none; }

a:hover {
  text-decoration: none; }

.fix {
  clear: both;
  height: 1px;
  margin: -1px 0 0;
  overflow: hidden; }

.fl {
  float: left; }

.fr {
  float: right; }

.ac {
  text-align: center; }

.ar {
  text-align: right; }

hr {
  margin-bottom: 30px;
  margin-top: 20px;
  clear: both; }

.hr2 {
  margin: 0px;
  padding: 0;
  clear: both; }

h1, h2, h3, h4, h5, h6 {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal; }

#wrapper h1 {
  margin: 0 0 15px 0;
  padding: 0;
  font-size: 24px;
  font-weight: bold; }

/* Wordpress default image  css class format ----------*/
.alignleft {
  float: left;
  padding: 4px;
  margin: 7px 25px 18px 0;
  text-align: center; }

.alignright {
  float: right;
  padding: 4px;
  margin: 24px 0 0px 12px;
  text-align: center; }

.aligncenter {
  text-align: center;
  padding: 5px;
  margin: 10px 0;
  background: #fff;
  border: 2px solid  #eee; }

.alignleft {
  border: 1px solid #a9a9a9;
  background: #fff; }

.alignright {
  border: 1px solid #a9a9a9;
  background: #fff; }

.wp-caption .alignleft {
  margin: 0 15px 10px 15px;
  padding: 0; }

.wp-caption p {
  margin: 0;
  padding: 3px 5px;
  font: bold 12px Arial, Helvetica, sans-serif; }

.wp-caption .alignright {
  float: right;
  margin: 0 0 10px 15px;
  padding: 0; }

.wp-caption {
  width: auto !important; }

/* Structure ---------------------- */
#outer {
  min-height: 700px; }

#header {
  padding: 5px 0;
  clear: both;
  position: relative; }

#page_nav {
  height: 30px;
  position: relative;
  z-index: 100; }

#categories_strip {
  height: 40px;
  position: relative;
  z-index: 101;
  margin-bottom: 25px; }

#wrapper {
  width: 938px;
  margin: 0 auto;
  padding: 0 20px; }

#banner {
  width: 716px;
  height: 430px;
  overflow: hidden;
  float: right;
  margin-bottom: 20px; }

.common_wrap {
  width: 716px;
  float: right; }

#content {
  width: 521px;
  padding-left: 30px;
  float: left;
  overflow: hidden; }

.content_inner {
  width: 685px !important; }

.full_page {
  width: 99% !important;
  padding-left: 0 !important; }

.content_left {
  width: 750px !important;
  padding-left: 0 !important; }

#sidebar_l {
  width: 222px;
  float: left; }

#sidebar_r {
  width: 135px;
  float: right; }

#footer {
  width: 938px;
  margin: 0 auto;
  padding: 0 20px;
  padding-top: 15px; }

/* header ---------------------- */
#categories_strip h3 {
  display: none; }

#header img {
  border: none; }

#header .blog-title {
  width: 375px;
  float: left;
  padding: 0 0 15px 0;
  text-transform: uppercase;
  line-height: normal; }

#header .blog-title a {
  font: bold 40px "Arial Black", Arial, Helvetica, sans-serif;
  padding: 0;
  margin: 0;
  text-decoration: none; }

#header .blog-title a:hover {
  text-decoration: none; }

#header p.blog-description {
  font: bold italic 12px Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  line-height: normal;
  text-transform: capitalize; }

.logo {
  text-align: left;
  /*float:left; padding-top:10px; padding-right:50px;*/
  margin: auto;
  width: 100%; }

.logo img {
  margin-bottom: 6px;
  width: 100%; }

.header_banner {
  width: 468px;
  height: 60px;
  float: left; }

#page_nav p.links {
  margin: 0;
  padding: 6px 10px 0px 0;
  float: right;
  font: 12px Arial, Helvetica, sans-serif; }

#page_nav p.links a {
  text-decoration: none;
  padding: 0 0px 0 20px;
  margin-left: 10px; }

.callnow {
  width: 110px;
  padding: 0px 0 0px 28px;
  float: right;
  font: 20px Arial Narrow, Arial, Helvetica, sans-serif; }

/*#breadcrumb section  =================================== */
.breadcrumb {
  font-size: 11px;
  padding: 8px 0;
  clear: both;
  margin-bottom: 30px; }

.breadcrumb {
  padding-left: 2px;
  font: 12px Arial, Helvetica, sans-serif; }

.breadcrumb a {
  font-weight: normal;
  text-decoration: none;
  padding: 0 3px 0 0;
  margin: 0;
  text-align: left;
  text-decoration: none; }

.breadcrumb strong {
  padding: 0 0 0 5px;
  margin: 0;
  font-weight: normal;
  text-align: left; }

.breadcrumb a:hover {
  text-decoration: none; }

/* content ---------------------------- */
p {
  margin: 0;
  padding: 0; }

small {
  font-size: 10px; }

.posts address {
  margin: 0;
  padding: 0; }

p {
  padding: 0px 0 10px 0;
  line-height: 20px;
  margin: 0; }

#content p {
  font-size: 14px;
  margin: 0 0 12px 0; }

#content h1.cat_head {
  margin: 0 0 5px 0;
  padding: 0 0 3px 0;
  font-size: 24px;
  font-weight: bold;
  min-height: 22px; }

#content h1.head {
  margin: 0; }

#content h1.single_head {
  margin: 0 0 10px 0;
  padding: 0 0 3px 0;
  font-size: 28px;
  font-weight: bold;
  min-height: 22px; }

#content ul {
  list-style: none; }

#content li {
  margin: 0 0 8px 0;
  padding: 0 0 5px 22px;
  line-height: 20px;
  font-size: 14px; }

#content ol {
  margin: 0 0 0 18px;
  padding: 0 0 10px 5px;
  list-style-type: decimal;
  line-height: 18px; }

#content ol li ol {
  margin: 8px 0 5px 18px;
  padding: 0; }

#content ol li {
  margin: 0 0 5px 0;
  padding: 0; }

/* car details ----------------------------------------------------- */
.button_bar {
  height: 100%;
  overflow: hidden;
  margin-bottom: 30px;
  height: 42px; }

a.b_print {
  display: block;
  float: left;
  padding: 6px 10px 6px 30px;
  margin-right: 10px;
  font: bold 14px Arial, Helvetica, sans-serif;
  cursor: pointer;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px; }

a.b_download_pdf {
  display: block;
  float: left;
  padding: 6px 10px 6px 30px;
  font: bold 14px Arial, Helvetica, sans-serif;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px; }

a.b_sendtofriend {
  display: block;
  float: right;
  margin-right: 2px;
  padding: 6px 10px 6px 30px;
  font: bold 14px Arial, Helvetica, sans-serif;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px; }

.likethis {
  overflow: hidden;
  padding-bottom: 15px;
  margin-bottom: 15px;
  clear: both; }

.facebook {
  padding-top: 10px;
  float: left; }

.twitter-share-button {
  float: left;
  padding-top: 12px; }

/* place listing detail page slideshow -------------------*/
.img_not_available_single {
  display: block;
  width: 335px;
  padding: 120px 0;
  float: left;
  font: 22px "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  text-align: center; }

.galleria-container {
  position: relative;
  overflow: hidden;
  width: 380px;
  float: left;
  height: 410px;
  margin-bottom: 10px; }

.galleria-container img {
  -moz-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none; }

.galleria-stage {
  position: absolute;
  top: 10px;
  bottom: 100px;
  left: 0px;
  right: 10px;
  overflow: hidden; }

.galleria-thumbnails-container {
  height: 75px;
  bottom: 0;
  position: absolute;
  left: 0px;
  right: 9px;
  z-index: 2; }

.galleria-carousel .galleria-thumbnails-list {
  margin-left: 40px;
  margin-right: 38px; }

.galleria-thumbnails .galleria-image {
  height: 55px;
  width: 80px;
  margin: 0 22px 0 0;
  float: left;
  cursor: pointer; }

.galleria-counter {
  position: absolute;
  bottom: 10px;
  left: 10px;
  text-align: right;
  font: normal 11px/1 arial,sans-serif;
  z-index: 2; }

.galleria-loader {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  display: none; }

.galleria-info {
  width: 50%;
  top: 15px;
  left: 15px;
  z-index: 2;
  position: absolute; }

.galleria-info-text {
  padding: 12px;
  display: none; }

.galleria-info-title {
  font: bold 12px/1.1 arial,sans-serif;
  margin: 0; }

.galleria-info-description {
  font: italic 12px/1.4 georgia,serif;
  margin: 0;
  color: #bbb; }

.galleria-info-title + .galleria-info-description {
  margin-top: 7px; }

.galleria-info-close {
  width: 9px;
  height: 9px;
  position: absolute;
  top: 5px;
  right: 5px;
  opacity: .5;
  cursor: pointer;
  display: none; }

.galleria-info-link {
  opacity: .8;
  position: absolute;
  width: 20px;
  height: 20px;
  cursor: pointer; }

.galleria-info-link:hover,
.galleria-info-close:hover {
  opacity: .5; }

.galleria-image-nav {
  position: absolute;
  top: 50%;
  margin-top: -15px;
  width: 100%;
  height: 31px;
  left: 0; }

.galleria-image-nav-left,
.galleria-image-nav-right {
  opacity: .7;
  cursor: pointer;
  width: 16px;
  height: 31px;
  position: absolute;
  left: 10px;
  z-index: 2; }

.galleria-image-nav-right {
  left: auto;
  right: 10px;
  background-position: -300px 0;
  z-index: 2; }

.galleria-image-nav-left:hover,
.galleria-image-nav-right:hover {
  opacity: 1.0; }

.galleria-thumb-nav-left,
.galleria-thumb-nav-right {
  cursor: pointer;
  display: none;
  background-position: -495px 11px;
  position: absolute;
  left: 0;
  top: 0;
  height: 60px;
  width: 32px;
  z-index: 3;
  opacity: 1.0; }

.galleria-thumb-nav-right {
  background-position: -570px 11px;
  border-right: none;
  right: 0;
  left: auto; }

.galleria-thumbnails-container .disabled,
.galleria-thumbnails-container .disabled:hover {
  opacity: .6;
  cursor: default; }

.galleria-thumb-nav-left:hover,
.galleria-thumb-nav-right:hover {
  opacity: 1;
  /*background-color:#111;*/ }

.galleria-carousel .galleria-thumb-nav-left,
.galleria-carousel .galleria-thumb-nav-right {
  display: block; }

.galleria-lightbox-box {
  z-index: 999999 !important; }

.vehicle_summary {
  width: 280px; }

.no_video {
  width: auto !important; }

#content .vehicle_summary p {
  margin: 0 0 12px 0;
  padding: 0;
  font: 14px Arial, Helvetica, sans-serif;
  clear: both;
  line-height: 18px; }

#content .vehicle_summary p span {
  float: left;
  margin-right: 10px;
  display: block;
  width: 150px; }

#content .vehicle_summary p.price {
  margin: 0 0 20px 0;
  padding: 0;
  font: bold 36px Arial, Helvetica, sans-serif; }

#content .vehicle_summary h4 {
  margin: 0 0 8px 0;
  padding: 0 0 5px 0;
  border-bottom: 1px solid #ddd;
  font: bold 16px Arial, Helvetica, sans-serif; }

.vehicle_features {
  clear: both;
  padding: 40px 0 20px 0; }

.vehicle_video {
  width: 360px;
  height: 400px;
  float: right;
  position: relative;
  z-index: 0; }

.vehicle_video object, .vehicle_video embed, .vehicle_video iframe {
  position: absolute;
  left: 0;
  top: 16px;
  z-index: 0 !important; }

#content .vehicle_video h4 {
  margin: 0 0 8px 0;
  padding: 0 0 5px 0;
  font: bold 16px Arial, Helvetica, sans-serif; }

.vehicle_video object, .vehicle_video embed {
  width: 360px !important;
  height: 300px !important; }

a.b_send_inquiry {
  display: block;
  padding: 10px 0 11px 39px;
  margin-bottom: 20px;
  width: 142px;
  font: 18px Arial, Helvetica, sans-serif; }

/* contact us -------*/
#content .form_row {
  height: 100%;
  overflow: hidden;
  margin-bottom: 24px; }

#content .form_row label {
  display: block;
  padding: 4px 0;
  margin-right: 10px; }

#content .form_row .textfield {
  font: 14px Arial, Helvetica, sans-serif;
  width: 350px;
  padding: 6px;
  border: 1px solid #ccc; }

#content .form_row .textarea {
  font: 14px Arial, Helvetica, sans-serif;
  width: 450px;
  padding: 6px;
  border: 1px solid #ccc; }

#content .error, .message_error2 {
  display: block;
  clear: both;
  padding-top: 4px;
  font-size: 11px; }

#contact_frm {
  width: 100%; }

#contact_frm .b_submit {
  font: bold 14px Arial, Helvetica, sans-serif;
  padding: 6px 24px;
  margin-bottom: 40px;
  cursor: pointer; }

#content p.success_msg {
  padding: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center; }

.indicates {
  font-size: 11px; }

/* content front widget -------------------- */
#content .widget {
  clear: both;
  margin-bottom: 30px;
  height: 100%;
  overflow: hidden; }

#content .widget h3 {
  margin: 0 0 10px 0;
  padding: 12px 0 12px 10px;
  font: 18px Arial, Helvetica, sans-serif;
  position: relative; }

#content .widget h3 a.more {
  position: absolute;
  right: 12px;
  top: 18px;
  padding-right: 10px;
  font: 10px Arial, Helvetica, sans-serif;
  text-transform: uppercase; }

/* cars manufactures ---------------------- */
#content .manufactures_by {
  margin: 0 0 5px 0px;
  width: 541px;
  padding: 0; }

#content .manufactures_by li {
  margin: 0 25px 0px 0;
  padding: 8px 0 8px 15px;
  width: 138px;
  font: 10px Arial, Helvetica, sans-serif;
  float: left; }

#content .manufactures_by li a {
  text-decoration: none;
  font: 14px Arial, Helvetica, sans-serif; }

/* sort by ----------------- */
.sort_by {
  height: 1005;
  overflow: hidden;
  margin-bottom: 40px; }

.sort_by span b {
  float: left;
  font: bold 12px Arial, Helvetica, sans-serif;
  padding: 7px 10px 0 0; }

.sort_by select {
  width: 180px;
  padding: 5px;
  font: 13px Arial, Helvetica, sans-serif; }

.sort_by a.previous {
  display: block;
  padding: 5px 5px 4px 15px;
  float: left;
  font: 11px Arial, Helvetica, sans-serif; }

.sort_by a.next {
  display: block;
  padding: 5px 15px 4px 5px;
  float: right;
  font: 11px Arial, Helvetica, sans-serif; }

/* new car deals -----------------------*/
#content .newcardeals {
  margin: 0;
  padding: 0; }

#content .newcardeals li {
  margin: 0 0 20px 0;
  padding: 0 0 25px 0;
  height: 100%;
  overflow: hidden; }

#content .newcardeals li .img_not_available {
  display: block;
  width: 135px;
  padding: 40px 0;
  float: left;
  font: bold 12px Arial, Helvetica, sans-serif;
  text-align: center; }

#content .newcardeals li a.post_img {
  padding: 2px;
  float: left;
  width: 134px; }

#content .newcardeals li h3 {
  width: 365px;
  float: right;
  margin: 0 0 10px 0;
  padding: 0 0 2px 0;
  min-height: 17px;
  font: bold 14px Arial, Helvetica, sans-serif; }

#content .newcardeals li h3 a {
  text-decoration: none;
  float: left;
  width: 310px; }

#content .newcardeals li h3 span {
  float: right; }

#content .newcardeals li p {
  width: 365px;
  float: right;
  margin: 0 0 10px 0;
  padding: 0;
  font: 13px Arial, Helvetica, sans-serif; }

#content .newcardeals li p.highlight_text {
  margin: 0 0 5px 0;
  font-weight: bold; }

#content .newcardeals li a.b_viewdetails {
  width: 110px;
  height: 19px;
  margin-right: 257px;
  padding: 4px 0;
  float: right;
  display: block;
  font: 12px Arial, Helvetica, sans-serif;
  text-align: center; }

#content .newcardeals li:last-child {
  background: none; }

/* slider --------------------------- */
#slider {
  overflow: hidden; }

.nivoSlider {
  position: relative; }

.nivoSlider img {
  position: absolute;
  top: 0px;
  left: 0px; }

.nivoSlider a.nivo-imageLink {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 305px !important;
  overflow: hidden !important;
  display: block;
  border: 0;
  padding: 0;
  margin: 0;
  z-index: 60;
  display: none; }

.nivo-slice {
  display: block;
  position: absolute;
  z-index: 50;
  height: 100%; }

.nivo-caption {
  position: absolute;
  left: 0px;
  bottom: 0px;
  opacity: 0.8;
  width: 100%;
  z-index: 89; }

.nivo-caption p {
  padding: 5px;
  margin: 0; }

.nivo-directionNav a {
  display: none;
  position: absolute;
  bottom: -22px;
  z-index: 99;
  cursor: pointer;
  opacity: 0.8;
  -moz-opacity: 0.8;
  -khtml-opacity: 0.8;
  filter: alpha(opacity=80); }

.nivo-prevNav {
  right: 20px;
  width: 32px;
  height: 32px;
  text-indent: -9009px;
  display: block; }

.nivo-prevNav:hover {
  width: 32px;
  height: 32px;
  color: #fff;
  opacity: 100;
  -moz-opacity: 100;
  -khtml-opacity: 100; }

.nivo-nextNav {
  right: 0px;
  width: 32px;
  height: 32px;
  text-indent: -9009px;
  display: block; }

.nivo-nextNav:hover {
  opacity: 100;
  -moz-opacity: 100;
  -khtml-opacity: 100; }

.nivo-controlNav {
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 9999; }

.nivo-controlNav a {
  position: relative;
  z-index: 9999;
  float: left;
  cursor: pointer;
  display: block;
  padding: 5px 10px;
  top: 10px;
  text-indent: -9009px; }

.nivo-controlNav a.active {
  font-weight: bold; }

/* search form ---------------- */
#srch_frm span#get_model_ajax_indicator {
  font: 11px Arial, Helvetica, sans-serif;
  color: #fff; }

#searchform {
  position: relative;
  height: 100%;
  overflow: hidden; }

.searchform {
  padding: 8px;
  display: block;
  right: 0;
  top: 32%; }

.searchform .s {
  padding: 8px;
  width: 91%;
  float: left;
  font: 13px Georgia, "Times New Roman", Times, serif; }

.search_btn {
  float: right;
  margin-top: 2px;
  position: absolute;
  right: 8px;
  top: 5px; }

.searchform, x:-moz-any-link {
  margin: 0; }

/* firefox */
/* post  ---------------------------- */
.comment_post {
  font: 12px Arial, Helvetica, sans-serif;
  display: block;
  padding-bottom: 10px; }

.comment_post a {
  display: block;
  width: 97px;
  height: 22px;
  text-align: left;
  padding: 4px 4px; }

.posts {
  height: 100%;
  overflow: hidden;
  margin-bottom: 60px; }

.post_spacer {
  margin-bottom: 10px !important; }

.pos_navigation {
  height: 100%;
  overflow: hidden;
  margin-bottom: 30px;
  padding-bottom: 20px; }

.post_left a, .post_right a {
  font: bold 16px Arial, Helvetica, sans-serif;
  padding: 10px;
  display: block; }

.main_img {
  padding: 5px;
  margin-bottom: 10px;
  margin-top: 20px; }

#content .posts p.date {
  font-size: 12px;
  height: 100%;
  overflow: hidden; }

.i_print {
  padding-left: 20px;
  float: right; }

#content .posts p.post_bottom {
  margin: 0;
  padding: 0 0 10px 0;
  clear: both; }

.by {
  font: 12px Arial, Helvetica, sans-serif;
  display: block;
  padding-bottom: 20px;
  margin-bottom: 15px; }

.by a {
  font-weight: bold; }

#content .posts p.post_bottom .category {
  float: left;
  font: 12px Arial, Helvetica, sans-serif;
  padding: 2px 0 0 20px; }

#content .posts p.post_bottom .tags {
  float: right;
  font: 12px Arial, Helvetica, sans-serif;
  padding: 2px 0 2px 20px; }

.small_thumb {
  height: 100%;
  overflow: hidden;
  margin-bottom: 20px;
  margin-left: -15px; }

.small_thumb img {
  float: left;
  margin: 0 0 8px 13px; }

.print {
  padding: 4px 0 30px 22px;
  display: block;
  font: bold 12px Arial, Helvetica, sans-serif;
  text-transform: uppercase; }

.b_text {
  display: block;
  font: bold 12px Arial, Helvetica, sans-serif;
  float: left;
  padding-top: 10px;
  padding-right: 70px; }

.post_right h2 {
  margin: 0 0 15px 0;
  padding: 0; }

.video_main {
  margin-bottom: 20px; }

.video_main object, .video_main embed {
  width: 517px !important;
  height: 400px !important; }

blockquote {
  clear: both;
  height: 100%;
  margin: 10px 0 0px 5px;
  overflow: hidden;
  padding: 0 0 5px 45px; }

#content blockquote p {
  font: italic 18px Georgia, "Times New Roman", Times, serif;
  margin: 0 0 5px 0;
  padding: 0;
  line-height: 23px; }

blockquote cite {
  font-size: 12px;
  float: right;
  line-height: 18px;
  text-align: right;
  display: block; }

blockquote a {
  font-size: 13px; }

#calendar_wrap {
  padding: 0;
  font-size: 13px; }

#wp-calendar {
  width: 100%; }

#wp-calendar caption {
  font-weight: bold;
  font: 15px Arial, Helvetica, sans-serif;
  text-transform: uppercase; }

#calendar_wrap caption {
  padding: 4px 0;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  margin-bottom: 4px;
  color: #333; }

#wp-calendar td {
  text-align: center;
  font: bold 12px Arial, Helvetica, sans-serif;
  padding: 4px 0; }

#wp-calendar th {
  text-align: center;
  font: bold 12px Arial, Helvetica, sans-serif;
  padding: 4px 0;
  color: #C63133; }

.posts h1 {
  font-size: 26px; }

.posts h2 {
  font-size: 24px;
  margin: 0;
  padding: 0; }

.posts h3 {
  font-size: 20px; }

.posts h4 {
  font-size: 18px; }

.posts h5 {
  font-size: 16px; }

.posts h6 {
  font-size: 14px; }

.content_spacer h4.cate_title {
  font-size: 26px;
  clear: both; }

dl, hr, h1, h2, h3, h4, h5, h6, ol, ul, pre, table, address, fieldset {
  padding: 10px 0 5px 0;
  margin: 0; }

p {
  padding: 0px 0 10px 0;
  margin: 0;
  line-height: 22px;
  margin: 0; }

p em {
  line-height: 20px; }

code {
  line-height: 18px; }

#content .realated_post {
  width: 685px;
  overflow: hidden; }

#content .realated_post h3 {
  margin: 0 0 12px 0;
  padding: 8px 0 5px 0;
  font: bold 14px Arial, Helvetica, sans-serif;
  color: #666;
  border-bottom: 1px solid #ddd; }

/* spotlight news ----------------------- */
#content .category_newslist {
  margin: 0 0 30px -35px;
  padding: 0;
  width: 745px;
  clear: both; }

#content .category_newslist li {
  margin: 0 0 40px 0;
  padding: 0;
  list-style: none;
  width: 145px;
  padding-left: 35px;
  float: left; }

#content .category_newslist li h3 {
  margin: 0;
  padding: 0;
  width: 145px;
  font: bold 14px Arial, Helvetica, sans-serif; }

#content .category_newslist li h3 a {
  text-decoration: none; }

#content .category_newslist li h3 a:hover {
  text-decoration: none; }

#content .category_newslist li .img_not_available {
  display: block;
  border: 1px solid #ddd;
  width: 140px;
  padding: 50px 0;
  float: left;
  font: bold 12px Arial, Helvetica, sans-serif;
  color: #656565;
  text-align: center;
  margin-bottom: 10px; }

#content .category_newslist li p.date {
  margin: 0 0 5px 0;
  padding: 0;
  width: 145px;
  font: 11px Arial, Helvetica, sans-serif; }

#content .category_newslist li img {
  margin: 0px 0 10px 0;
  padding: 5px; }

#content .category_newslist li p.date span {
  float: left; }

#content .category_newslist li p.date .pcomments {
  float: right;
  margin-top: -2px;
  font: bold 12px Georgia, "Times New Roman", Times, serif;
  padding-right: 15px; }

#content .category_newslist li.hr {
  display: block;
  width: 521px;
  clear: both;
  height: 40px; }

#content .category_newslist li:last-child {
  display: none; }

/* Post Pagination */
.pagination {
  clear: both;
  overflow: hidden;
  margin-bottom: 20px;
  text-align: center; }

.pagination .on {
  padding: 14px;
  margin-right: 3px;
  display: inline; }

.pagination a {
  display: inline;
  padding: 14px;
  line-height: 32px;
  margin-right: 3px; }

.navi {
  overflow: hidden; }

/* single page ---------- */
.main_img {
  padding: 5px;
  margin-bottom: 10px;
  margin-top: 20px;
  clear: both; }

/* archive page list----------------------- */
#content .news_list {
  margin: 0;
  padding: 0; }

#content .news_list li {
  margin: 0 0 40px 0;
  padding: 0 0 40px 0;
  list-style: none;
  position: relative; }

#content .news_list li h3 {
  margin: 0;
  padding: 0;
  font: 22px Arial, Helvetica, sans-serif; }

#content .news_list li h3 a {
  text-decoration: none; }

#content .news_list li h3 a:hover {
  text-decoration: none; }

#content .news_list li p.date {
  margin: 3px 0;
  padding: 0;
  font: italic 14px Arial, Helvetica, sans-serif; }

#content .news_list li p.postedby {
  margin: 3px 0 15px 0;
  padding: 0;
  font: italic 12px Georgia, "Times New Roman", Times, serif; }

#content .news_list li p.postedby span.author {
  float: left; }

#content .news_list li p.postedby .pcomments {
  float: right;
  margin-left: 40px;
  font: bold 12px Georgia, "Times New Roman", Times, serif;
  padding-right: 15px; }

#content .news_list li img {
  float: left;
  margin: 5px 15px 0 0;
  padding: 5px; }

#content .news_list li a.more {
  display: block;
  position: absolute;
  right: 0;
  bottom: 8px;
  float: right;
  padding: 6px 15px 6px 10px;
  font: 12px Arial, Helvetica, sans-serif; }

/*#sidebar ----------------------- */
.sidebar .search_sidebar h3 {
  margin: 0;
  padding: 9px 0 9px 10px;
  font: bold 16px Arial, Helvetica, sans-serif; }

.sidebar .search_sidebar {
  padding-bottom: 200px;
  height: 400px;
  margin-bottom: 20px; }

.sidebar .search_sidebar label {
  display: block;
  padding: 10px 0px 0 13px;
  clear: both; }

.sidebar .search_sidebar select {
  width: 195px;
  padding: 5px;
  font: bold 13px Arial, Helvetica, sans-serif; }

.b_searchcar {
  width: 195px;
  height: 45px;
  margin: 14px 0 0 13px;
  cursor: pointer;
  font: 18px Arial, Helvetica, sans-serif; }

/* widget ------ */
.sidebar .widget {
  margin-bottom: 20px;
  overflow: hidden; }

.sidebar .widget h3 {
  margin: 0;
  padding: 10px 0 10px 10px;
  font: bold 14px Arial, Helvetica, sans-serif; }

.sidebar .widget li {
  margin: 0;
  padding: 0;
  font: 13px Arial, Helvetica, sans-serif; }

.sidebar .widget li a {
  padding: 7px 0 7px 25px;
  text-decoration: none;
  display: block; }

.sidebar .widget p {
  margin: 0 0 12px 0;
  padding: 0px 12px  0 12px;
  font: 12px Arial, Helvetica, sans-serif;
  line-height: 18px; }

.sidebar #twitter_update_list li {
  margin: 0 0 12px 0;
  padding: 0 10px 5px 22px; }

.sidebar #twitter_update_list li a {
  display: inherit;
  padding: 0; }

.sidebar #twitter p {
  margin: 0 12px 12px 12px;
  padding: 0px 23px 0 0;
  text-align: right;
  font: bold 12px Arial, Helvetica, sans-serif;
  line-height: 18px; }

#sidebar_l #twitter p {
  padding: 0 0 0 23px; }

.subscribe_form {
  width: 197px;
  height: 33px;
  margin: 0 0 10px 10px;
  position: relative; }

* + html .subscribe_form {
  padding-bottom: 10px; }

.subscribe_form .field {
  width: 158px;
  padding: 10px 0 0 8px;
  font: 13px Arial, Helvetica, sans-serif;
  float: left; }

.subscribe_form .btn_submit {
  width: 16px;
  height: 14px;
  cursor: pointer;
  margin-top: 11px; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .subscribe_form .field {
    padding-top: 14px; }
  /* Safari 3.0 and Chrome rules here */ }

.flickr {
  clear: both;
  margin-bottom: 25px; }

.flickr img {
  padding: 3px;
  margin: 0 4px 8px 4px;
  float: left;
  width: 80px;
  height: 65px; }

.bookmark {
  padding: 5px 0;
  margin-bottom: 20px; }

.bookmark img {
  margin-left: 9px; }

/* subscribe section -----------*/
.socialmedia {
  padding: 10px;
  margin-bottom: 25px; }

.subscribe {
  padding: 10px; }

.subscribe p {
  margin: 0 0 8px 0;
  padding: 0 0 0 35px; }

.subscribe .field {
  float: left;
  padding: 6px;
  width: 160px; }

.subscribe .btn_submit {
  width: 72px;
  height: 27px;
  margin-top: 2px;
  cursor: pointer;
  padding: 3px;
  font: bold 12px Arial, Helvetica, sans-serif;
  float: right; }

.subscribe .btn_submit, x:-moz-any-link {
  margin: 0; }

/* firefox */
/* featured video */
#sidebar .featured_video {
  padding: 10px;
  padding-bottom: 0; }

#sidebar .featured_video .video embed, #sidebar .featured_video .video object {
  width: 270px !important;
  height: 185px !important;
  margin-bottom: 10px; }

#sidebar .featured_video h4 {
  margin: 0 0 20px 0;
  padding: 0;
  font-weight: bold; }

.advt4widget {
  padding: 10px;
  padding-bottom: 5px;
  margin-bottom: 25px; }

.advt4widget img {
  margin-bottom: 10px;
  margin-left: 5px; }

#sidebar .advt {
  height: 100%;
  overflow: hidden;
  margin: 0 10px;
  padding: 15px;
  padding-bottom: 0; }

#sidebar .advt img {
  margin-bottom: 18px; }

#sidebar .popular_post {
  height: 100%;
  overflow: hidden;
  margin: 10px;
  margin-top: 0;
  padding-bottom: 0; }

#sidebar .popular_post h3 {
  margin: 0 0 8px 0;
  padding: 10px 0 10px 10px;
  font-size: 18px; }

#sidebar .popular_post {
  padding-bottom: 10px; }

#sidebar .popular_post li {
  margin: 0 0 15px 0;
  padding: 5px 0 15px 10px; }

#sidebar .popular_post li a {
  font-size: 14px;
  font-weight: bold; }

#sidebar .popular_post li span {
  font: 11px Georgia, "Times New Roman", Times, serif;
  display: block;
  clear: both; }

#sidebar .popular_post li span a {
  font-size: 11px;
  font-weight: normal; }

#sidebar .advt {
  height: 100%;
  overflow: hidden;
  margin-bottom: 25px;
  padding: 15px;
  padding-bottom: 0; }

.advt_single {
  margin-bottom: 20px;
  text-align: center; }

#sidebar_l .subscribe .field {
  width: 125px;
  margin-bottom: 10px; }

.subscribe p {
  margin: 0 0 6px 0;
  padding: 2px 0 2px 0;
  font: 12px Arial, Helvetica, sans-serif; }

.subscribe p a {
  font-weight: bold;
  margin-right: 10px;
  padding: 2px 0 2px 20px;
  display: block; }

#sidebar_r .subscribe p a {
  display: inline; }

#content .content_sub_sidebar_left .field {
  width: 125px;
  margin-bottom: 10px; }

#content .content_sub_sidebar_right .field {
  width: 135px;
  margin-bottom: 10px; }

#content .content_sub_sidebar_right .subscribe p a {
  display: inline; }

#sidebar ul {
  list-style: none;
  margin: 0;
  padding: 0; }

#sidebar li {
  list-style: none;
  margin: 0 0 8px 0;
  padding: 2px 0 5px 15px;
  font: 14px Arial, Helvetica, sans-serif; }

#sidebar ul li ul li ul li {
  margin: 0 0 0 10px; }

.sidebar #recentcomments {
  margin: 10px 10px;
  padding: 0 0 0 0; }

.sidebar .widget #recentcomments li {
  margin: 0;
  padding: 6px 0 8px 15px !important; }

.sidebar .widget #recentcomments li a {
  padding: 0; }

/*---------ARCHIVE PAGE --------- */
.arclist {
  margin-bottom: 30px; }

/*---------COMMENTS --------- */
#comments {
  padding: 15px 0;
  clear: both; }

#comments .commentlist {
  padding: 0;
  margin: 0; }

.comment_left {
  position: absolute;
  left: -17px;
  top: 10px; }

#comments .commentlist li {
  border-bottom: 1px solid #ddd; }

#comments .commentlist li p {
  margin: 0;
  padding: 0; }

#comments .commentlist li #respond p {
  margin-bottom: 12px; }

#comments ol {
  padding: 20px 0; }

#content #comments .comment {
  margin: 10px 0 30px 0;
  padding: 0;
  width: 100%;
  background: none;
  list-style: none;
  background: #fff url(skins/1-default/hr.png) repeat-y 170px; }

#comments .commentlist ul.children li {
  border-top: 1px solid #ddd; }

#content #comments p.authorcomment {
  margin: 3px 0 10px 0;
  padding: 0 0 8px 0;
  line-height: normal;
  font-size: 13px; }

#comments p.authorcomment a {
  text-decoration: none;
  font-weight: bold; }

#comments p.authorcomment a:hover {
  text-decoration: none; }

#comments .comment .meta-left {
  float: left;
  width: 150px;
  margin-right: 10px;
  padding-right: 10px;
  padding-top: 15px; }

#comments .comment .meta-left p {
  padding: 0;
  margin: 0; }

#comments .comment .meta-left .meta-wrap {
  width: 60px;
  overflow: hidden;
  padding-right: 7px;
  min-height: 70px;
  line-height: normal; }

* html #comments .comment .text-right {
  height: 70px; }

#comments .comment .text-right {
  min-height: 70px;
  margin: 0;
  padding: 12px;
  margin-left: 170px;
  float: none;
  position: relative; }

#comments .comment .text-right p {
  line-height: 20px;
  padding: 0 10px 8px 0px;
  margin: 0;
  height: 100%;
  overflow: hidden;
  font: 13px Arial, Helvetica, sans-serif; }

#comments ul.children {
  margin: 10px 0 0 20px;
  padding: 0; }

p.commpadd {
  padding: 0;
  margin: 0 0 10px 0;
  position: relative; }

span.comm-reply {
  float: right;
  padding: 5px 0 0 0;
  font-size: 11px; }

span.comm-reply a:hover {
  font-size: 11px;
  text-decoration: none; }

.cancel-comment-reply {
  padding: 0 0 10px 0; }

#respond {
  /* padding:15px; */
  background: #fff; }

#respond textarea, .commpadd input {
  font: 14px Arial, Helvetica, sans-serif;
  padding: 10px 5px; }

#respond textarea {
  margin: 0;
  padding-left: 10px;
  padding-bottom: 10px;
  width: 440px;
  height: 138px; }

p.commpadd input {
  line-height: 25px;
  width: 320px;
  padding: 8px 5px 9px 8px;
  /*padding:8px 15px 8px 10px;*/ }

* + html p.commpadd input {
  padding: 4px 5px 9px 8px; }

p.commpadd label {
  padding: 0 0 0 5px;
  float: left;
  display: block;
  width: 80px;
  padding-top: 5px; }

p.commpadd small {
  font-size: 12px; }

input#submit {
  font: bold 12px Arial, Helvetica, sans-serif;
  text-align: center;
  padding: 8px 15px;
  margin: 0 0 50px 45px;
  cursor: pointer;
  text-transform: uppercase;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  float: left; }

.avatar {
  padding: 7px;
  float: right; }

#comments a.url {
  font: bold 13px Arial, Helvetica, sans-serif;
  color: #444;
  display: block; }

#comments a:hover.url {
  color: #b10000; }

#comments .comment small {
  font-size: 13px;
  font-style: italic;
  padding-bottom: 4px;
  display: block; }

#respond h3, #comments_wrap h3 {
  font-size: 18px;
  border-bottom: 1px solid #ddd;
  color: #4f4f4f; }

#comments .commentlist .photo {
  width: auto;
  height: auto;
  margin: 0 0 3px 0; }

#comments .comment a.comment-reply-link {
  display: block;
  float: left;
  font: bold 10px Arial, Helvetica, sans-serif;
  padding: 3px 15px 3px 10px;
  margin-bottom: 10px; }

#respond .author {
  display: block;
  float: left;
  width: 20px;
  height: 15px;
  margin: 8px 20px 0 0; }

#respond .email {
  display: block;
  float: left;
  width: 20px;
  height: 15px;
  margin: 8px 20px 0 0; }

#respond .website {
  display: block;
  float: left;
  width: 20px;
  height: 15px;
  margin: 8px 20px 0 0; }

#respond .icomment {
  display: block;
  float: left;
  width: 20px;
  height: 15px;
  margin: 8px 20px 0 0; }

#respond .comment_arrow {
  width: 14px;
  height: 17px;
  display: block;
  position: absolute;
  left: 36px;
  top: 8px; }

/* page navigation --------------------------------- */
#page_nav ul, #page_nav ul li, #page_nav ul ul {
  list-style: none;
  margin: 0;
  padding: 0; }

#page_nav ul {
  position: relative;
  z-index: 597;
  float: left;
  font-family: Arial, Helvetica, sans-serif; }

#page_nav ul li {
  float: left;
  line-height: 1.5em;
  vertical-align: middle;
  zoom: 1; }

#page_nav ul li.hover, #page_nav ul li:hover {
  position: relative;
  z-index: 599;
  cursor: default; }

#page_nav ul ul {
  visibility: hidden;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 598;
  width: 100%; }

#page_nav ul ul li {
  float: none;
  font-size: 12px; }

#page_nav ul ul ul {
  top: 0px;
  left: 99%; }

#page_nav ul li:hover > ul {
  visibility: visible;
  display: block; }

/* Default CSS Drop-Down Menu Theme*/
#page_nav ul {
  font-weight: bold;
  font-size: 14px; }

#page_nav ul li a {
  padding: 5px 15px;
  display: block;
  cursor: pointer; }

#page_nav ul li a:hover {
  display: block; }

#page_nav ul li a:link, #page_nav ul li a:visited {
  text-decoration: none;
  display: block;
  cursor: pointer; }

/* -- level mark -- */
#page_nav ul ul {
  width: 190px;
  margin-top: 0px;
  border-right: 1px solid #494949;
  border-left: 1px solid #494949; }

#page_nav ul ul ul.children {
  margin-top: 2px;
  margin-left: 2px; }

#page_nav ul ul li {
  font-weight: bold;
  margin-top: 0; }

#page_nav li {
  font-size: 12px;
  margin-right: 2px; }

#page_nav #page_nav ul ul li {
  float: none;
  font-size: 12px; }

/* Categories navigation --------------------------------- */
#categories_strip ul, #categories_strip ul li, #categories_strip ul ul {
  list-style: none;
  margin: 0;
  padding: 0; }

#categories_strip ul {
  position: relative;
  z-index: 597;
  float: left;
  font-family: Arial, Helvetica, sans-serif; }

#categories_strip ul li {
  float: left;
  line-height: 1.4em;
  vertical-align: middle;
  /*text-transform:uppercase;*/ }

#categories_strip ul li.hover, #categories_strip ul li:hover {
  position: relative;
  z-index: 599;
  cursor: default; }

#categories_strip ul ul {
  visibility: hidden;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 598;
  width: 100%; }

#categories_strip ul ul li {
  float: none;
  font-size: 12px; }

#categories_strip ul ul ul {
  top: 0;
  left: 99%; }

#categories_strip ul li:hover > ul {
  visibility: visible;
  display: block; }

/* Default CSS Drop-Down Menu Theme*/
#categories_strip ul {
  font-weight: bold;
  font-size: 14px; }

#categories_strip ul li a {
  padding: 10px 15px 9px 15px;
  display: block; }

#categories_strip ul li a:hover {
  display: block; }

#categories_strip ul li a:link, #categories_strip ul li a:visited {
  text-decoration: none;
  display: block; }

/* -- level mark -- */
#categories_strip ul ul {
  width: 190px;
  margin-top: 0px; }

#categories_strip ul ul li ul li ul {
  margin-top: 2px;
  margin-left: 2px; }

#categories_strip ul ul li {
  font-weight: bold;
  margin-top: 0px; }

#categories_strip li {
  font-size: 13px; }

#categories_strip ul ul li {
  float: none;
  font-size: 12px; }

#categories_strip ul li ul li a {
  padding: 10px 15px;
  line-height: normal;
  text-transform: capitalize; }

/* footer ---------------------------- */
#footer p {
  margin: 0 0 8px 0;
  padding: 0;
  line-height: 20px;
  font: 12px Arial, Helvetica, sans-serif; }

#footer .designby {
  padding: 8px 8px 0 0;
  float: left; }

#footer p span.templatic {
  width: 80px;
  display: block;
  overflow: hidden;
  float: left; }

#footer p span.templatic a {
  text-indent: -9009px;
  padding-left: 120px;
  height: 30px;
  display: block; }

#footer p.copy {
  float: left;
  padding-top: 7px; }

/* Popup */
#basic-modal-content, #basic-modal-content2 {
  display: none; }

/* Overlay */
#simplemodal-overlay {
  cursor: wait;
  position: relative; }

/* Container */
#simplemodal-container {
  padding: 12px;
  display: block;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px; }

#simplemodal-container a.modalCloseImg {
  width: 25px;
  height: 29px;
  display: inline;
  z-index: 3200;
  position: absolute;
  top: -15px;
  right: -16px;
  cursor: pointer; }

#simplemodal-container #basic-modal-content {
  padding: 8px;
  height: 100%; }

#simplemodal-container h3 {
  margin: 0 0 20px 0;
  padding: 0 0 5px 0;
  font: 22px Georgia, "Times New Roman", Times, serif; }

#simplemodal-container .row {
  margin-right: 10px;
  margin-bottom: 10px; }

#simplemodal-container .row label {
  display: block;
  float: left;
  font-size: 13px;
  color: #333;
  padding: 5px 0;
  width: 95px; }

#simplemodal-container .row textarea {
  height: 150px; }

#simplemodal-container .row input, #simplemodal-container .row textarea {
  font: 14px Arial, Helvetica, sans-serif;
  padding: 5px;
  width: 268px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  float: left; }

#simplemodal-container .button {
  font: bold 12px Arial, Helvetica, sans-serif;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  padding: 5px 15px;
  margin-right: 5px;
  clear: left;
  cursor: pointer;
  margin-left: 95px; }

#simplemodal-container {
  top: 20px !important; }

#basic-modal-content2 {
  height: 430px;
  width: 390px; }

#basic-modal-content {
  width: 400px;
  height: 480px; }

#basic-modal-content {
  height: 556px; }

#basic-modal-content .message_error2, #basic-modal-content2 .message_error2 {
  margin-left: 95px;
  font: 11px Arial, Helvetica, sans-serif;
  color: #900; }

/* popup window ---------*/
#simplemodal-overlay {
  background-color: #000; }

#simplemodal-container {
  color: #666;
  background-color: #fff;
  border: 4px solid #ddd; }

#simplemodal-container a.modalCloseImg {
  background: url(images/x.png) no-repeat; }

#simplemodal-container h3 {
  color: #970101;
  border-bottom: 1px solid #ccc; }

#simplemodal-container .row label {
  color: #333; }

#simplemodal-container .row input, #simplemodal-container .row textarea {
  border: 1px solid #ccc; }

#simplemodal-container .button {
  background: #333;
  color: #fff;
  border: none; }

#simplemodal-container .button:hover {
  background: #970101; }

.sucess_msg {
  font: 14px Arial, Helvetica, sans-serif;
  clear: both;
  text-align: center;
  padding: 8px;
  margin-bottom: 20px;
  color: #4b6b0d;
  background: #dfe9b5;
  border-top: 1px solid #8c9f42;
  border-bottom: 1px solid #8c9f42; }

.error_msg_fix {
  display: block;
  font: 14px Arial, Helvetica, sans-serif;
  clear: both;
  text-align: center;
  padding: 8px;
  margin: 10px 0;
  background: #ffe7e7;
  border-top: 1px solid #c54848;
  border-bottom: 1px solid #c54848;
  color: #a70a0a; }

/* clearfix ----- */
.clear {
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0; }

.clearfix:after {
  clear: both;
  content: ' ';
  display: block;
  font-size: 0;
  line-height: 0;
  visibility: hidden;
  width: 0;
  height: 0; }

.clearfix {
  display: inline-block; }

* html .clearfix {
  height: 1%; }

.clearfix {
  display: block; }

/* SLIMBOX */
#lbOverlay {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  cursor: pointer; }

#lbCenter, #lbBottomContainer {
  position: absolute;
  z-index: 9999;
  overflow: hidden;
  background-color: #fff; }

.lbLoading {
  background: #fff url(loading.gif) no-repeat center; }

#lbImage {
  position: absolute;
  left: 0;
  top: 0;
  border: 10px solid #fff;
  background-repeat: no-repeat; }

#lbPrevLink, #lbNextLink {
  display: block;
  position: absolute;
  top: 0;
  width: 50%;
  outline: none; }

#lbPrevLink {
  left: 0; }

#lbPrevLink:hover {
  background: transparent url(prevlabel.gif) no-repeat 0 15%; }

#lbNextLink {
  right: 0; }

#lbNextLink:hover {
  background: transparent url(nextlabel.gif) no-repeat 100% 15%; }

#lbBottom {
  font-family: Verdana, Arial, Geneva, Helvetica, sans-serif;
  font-size: 10px;
  color: #666;
  line-height: 1.4em;
  text-align: left;
  border: 10px solid #fff;
  border-top-style: none; }

#lbCloseLink {
  display: block;
  float: right;
  width: 66px;
  height: 22px;
  background: transparent url(closelabel.gif) no-repeat center;
  margin: 5px 0;
  outline: none; }

#lbCaption, #lbNumber {
  margin-right: 71px; }

#lbCaption {
  font-weight: bold; }

/*	--------------------------------------------------
Global Reset - Resets all browser defaults to 0
-------------------------------------------------- */
body {
  background: #157198 url(/assets/img/bodybg.png) repeat-x left top;
  color: #444; }

#outer {
  background: url(/assets/img/pattern.png) repeat left top; }

.posts h2 a, .posts p a:hover, a {
  color: #000; }

.more a {
  font-size: 12px; }

p {
  margin: 0;
  padding: 0; }

a:hover, .posts h2 a:hover, .posts p a {
  color: #157198; }

hr {
  color: #fffdea;
  border: none;
  border-top: 1px dotted #372322; }

.hr2 {
  color: #fffdea;
  border: none; }

/* headings ---------------------- */
h1, h2, h3, h4, h5, h6 {
  color: #000; }

/* Structure ---------------------- */
#wrapper {
  width: 938px;
  margin: 0 auto;
  padding: 0 20px;
  background: #fff; }

#banner {
  background: #000; }

#footer {
  background: #f7f7f7;
  border-top: 3px double #dedede; }

#page_nav {
  background: #fff url(/assets/img/pagenavbg.png) repeat-x left bottom; }

#categories_strip {
  background: #939393 url(/assets/img/navbg.png) repeat-x left bottom; }

.content_sub_sidebar_right {
  border-left: 1px solid #e2e2e2;
  border-right: 1px solid #e2e2e2; }

#content h1.cat_head {
  color: #37809f;
  border-bottom: 1px solid #e5e5e5; }

#content h1.single_head {
  color: #37809f;
  border-bottom: 1px solid #e5e5e5; }

.callnow {
  background: url(/assets/img/callnow.png) no-repeat left top; }

.callnow span {
  color: #a8a8a8; }

#page_nav p.links a.rss {
  background: url(../images/i_rss2.png) no-repeat 2px top; }

#page_nav p.links a.email {
  background: url(../images/i_email.png) no-repeat left top; }

/* header ---------------------- */
#header .blog-title a {
  color: #000; }

#header .blog-title a:hover {
  color: #157198; }

#header .blog-description {
  color: #6c6c6c; }

/*---------TOP NAVIGATION for PAGES---------  */
#page_nav p.links {
  color: #7d7d7d; }

#page_nav p.links a {
  color: #7d7d7d; }

#page_nav p.links a:hover {
  color: #000; }

/* ----searchform ------ */
.searchform {
  background: #e5e5e5; }

.searchform .s {
  color: #b2b2b2;
  background: #fff;
  border: none; }

.searchform .s:focus {
  color: #333; }

.search_btn {
  background: url(/assets/img/b_search.png) no-repeat left top; }

/*#breadcrumb section  =================================== */
.breadcrumb {
  border-bottom: 1px dotted #dfdfdf;
  background: #fff;
  color: #777; }

.breadcrumb a {
  color: #157198; }

.breadcrumb a:hover {
  color: #000; }

/* content ---------------------------- */
#content li {
  background: url(/assets/img/arrow_cat.png) no-repeat left 2px; }

#content ol li {
  background: none; }

/* car details ----------------------------------------------------- */
a.b_print {
  color: #535353;
  background: url(/assets/img/b_print.png) no-repeat left top;
  border: 1px solid #dfdfdf;
  -moz-box-shadow: 1px 1px 1px #ddd;
  -webkit-box-shadow: 1px 1px 1px #ddd; }

a.b_download_pdf {
  color: #535353;
  background: url(/assets/img/b_download_pdf.png) no-repeat left top;
  border: 1px solid #dfdfdf;
  -moz-box-shadow: 1px 1px 1px #ddd;
  -webkit-box-shadow: 1px 1px 1px #ddd; }

a.b_sendtofriend {
  color: #535353;
  background: url(/assets/img/b_sendtofriend.png) no-repeat left top;
  border: 1px solid #dfdfdf;
  -moz-box-shadow: 1px 1px 1px #ddd;
  -webkit-box-shadow: 1px 1px 1px #ddd; }

.likethis {
  border-bottom: 1px solid #eee; }

/* gallery - detail page slideshow -------------------*/
.img_not_available_single {
  border: 1px solid #ddd;
  color: #bbb; }

.galleria-thumbnails .galleria-image {
  background: #fff;
  border: 1px solid #ccc;
  border-bottom: 2px solid #fff; }

.galleria-counter {
  color: #fff; }

.galleria-loader {
  background: #000;
  background: url(../images/classic-loader.gif) no-repeat 2px 2px; }

.galleria-info-text {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.galleria-info-title {
  color: #fff; }

.galleria-info-description {
  color: #bbb; }

.galleria-info-close {
  background-position: -753px -11px; }

.galleria-info-link {
  background-position: -669px -5px;
  background-color: #000; }

.galleria-image-nav-right {
  background-position: -300px 0; }

.galleria-thumb-nav-left,
.galleria-thumb-nav-right {
  background-position: -495px 11px; }

.galleria-thumb-nav-right {
  background-position: -570px 11px;
  border-right: none; }

.galleria-thumb-nav-left,
.galleria-thumb-nav-right,
.galleria-info-link,
.galleria-info-close,
.galleria-image-nav-left,
.galleria-image-nav-right {
  background-image: url(../images/classic-map.png);
  background-repeat: no-repeat; }

#content .vehicle_summary p {
  color: #4f4f4f; }

#content .vehicle_summary p span {
  color: #000; }

#content .vehicle_summary p.price {
  color: #37809f; }

#content .vehicle_summary h4 {
  border-bottom: 1px solid #ddd;
  color: #0c729d; }

#content .vehicle_video h4 {
  border-bottom: 1px solid #ddd;
  color: #0c729d; }

a.b_send_inquiry {
  color: #fff;
  text-shadow: 1px 1px 1px #333;
  background: url(/assets/img/b_sendinquiry.png) no-repeat left top; }

a:hover.b_send_inquiry {
  color: #fff;
  background-position: 0 -43px; }

/* content front widget -------------------- */
#content .widget h3 {
  background: #e7e6e6 url(/assets/img/content_titlebg.png) repeat-x left bottom;
  border-bottom: 1px solid #d8d8d8; }

#content .widget h3 a.more {
  position: absolute;
  right: 12px;
  top: 18px;
  padding-right: 10px;
  font: 10px Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  color: #870000;
  background: url(/assets/img/bullet2.png) no-repeat right 2px; }

#content .widget h3 a:hover.more {
  color: #000; }

/* cars manufactures ---------------------- */
#content .manufactures_by li {
  color: #a4a4a4;
  background: url(/assets/img/bullet.png) no-repeat left 12px;
  border-bottom: 1px solid #ddd; }

#content .manufactures_by li a {
  color: #787878; }

#content .manufactures_by li a:hover {
  color: #157198; }

/* sort by ----------------- */
.sort_by select {
  border: 1px solid #ddd; }

.sort_by a.previous {
  background: #e7e7e7 url(/assets/img/pprevious.png) no-repeat 5px 7px;
  color: #000;
  border-right: 2px solid #fff; }

.sort_by a:hover.previous {
  color: #157198; }

.sort_by a.next {
  background: #e7e7e7 url(/assets/img/pnext.png) no-repeat 34px 7px;
  border-left: 2px solid #fff;
  color: #000; }

.sort_by a:hover.next {
  color: #157198; }

/* new car deals -----------------------*/
#content .newcardeals li {
  background: url(/assets/img/sepretor.png) no-repeat center bottom; }

#content .newcardeals li .img_not_available {
  border: 1px solid #ddd;
  color: #656565; }

#content .newcardeals li a.post_img {
  border: 1px solid #ddd; }

#content .newcardeals li h3 {
  background: none;
  border-bottom: 1px solid #ddd; }

#content .newcardeals li h3 a {
  color: #157198; }

#content .newcardeals li h3 a:hover {
  color: #000; }

#content .newcardeals li h3 span {
  color: #157198; }

#content .newcardeals li p {
  color: #656565;
  background: none;
  border: none; }

#content .newcardeals li p.highlight_text {
  color: #000; }

#content .newcardeals li a.b_viewdetails {
  color: #fff;
  background: url(/assets/img/b_viewdetails.png) no-repeat left top; }

#content .newcardeals li a:hover.b_viewdetails {
  background-position: 0 -27px;
  color: #fff; }

/* slider --------------------------- */
#slider {
  border-left: 1px solid #0c79a7; }

.nivoSlider a.nivo-imageLink {
  border: 0; }

.nivo-caption {
  background: #000;
  color: #fff; }

.nivo-prevNav {
  background: url(../images/previous.png) no-repeat left top; }

.nivo-prevNav:hover {
  background: url(../images/previous.png) no-repeat left top;
  color: #fff; }

.nivo-nextNav {
  background: url(images/next.png) no-repeat left top; }

.nivo-nextNav:hover {
  background: url(../images/next.png) no-repeat left top; }

.nivo-controlNav a {
  color: #fff; }

/* banner nav ---- */
.nivo-controlNav a {
  background: url(/assets/img/dot_n.png) no-repeat;
  border: none;
  color: #fff; }

.nivo-controlNav a:hover, .nivo-controlNav a.active {
  background: url(/assets/img/dot_h.png) no-repeat; }

/* post ---------------------------- */
.comment_post a {
  background: url(/assets/img/comment_bg.png) no-repeat; }

.posts {
  border-bottom: 1px solid #ddd; }

.main_img {
  border: 1px solid #ccc;
  background: #fff; }

.date {
  color: #858585; }

.by {
  color: #858585;
  border-bottom: 1px solid #e3e3e3; }

#content .posts p.post_bottom .category {
  color: #858585;
  background: url(../images/i_category.png) no-repeat left 0px; }

.tags {
  color: #858585;
  background: url(../images/i_tags.png) no-repeat left 2px; }

.post_left a {
  color: #157198;
  background: url(/assets/img/pprevious.png) no-repeat left 16px; }

.post_left a:hover {
  color: #000; }

.post_right a {
  color: #157198;
  background: url(/assets/img/pnext.png) no-repeat right 16px; }

.post_right a:hover {
  color: #000; }

.b_text {
  font: bold 12px Arial, Helvetica, sans-serif;
  color: #858585; }

.bookmark {
  border-top: 2px solid #ebebeb; }

.video_main {
  border: 2px solid #ccc; }

code {
  background: #ccc; }

blockquote {
  background: url(/assets/img/quote.png) no-repeat; }

blockquote p {
  color: #333; }

blockquote cite {
  color: #666; }

blockquote a {
  color: #333; }

blockquote a:hover {
  color: #000; }

.flickr img {
  border: 1px solid #ddd;
  background: #fff; }

.flickr img:hover {
  border-color: #333; }

#content .posts p.date {
  color: #a9a9a9; }

#content .posts p.date a {
  color: #444;
  border-bottom: 1px dotted #ccc; }

#content .posts p.date a:hover {
  color: #000; }

/* Post Pagination */
.pagination .on {
  background: #810000 url(/assets/img/page_nav_h.png) repeat left top;
  color: #fff; }

.pagination a {
  background: #333 url(/assets/img/page_nav_n.png) repeat left top;
  color: #fff; }

.pagination a:hover {
  background: #810000 url(/assets/img/page_nav_h.png) repeat left top;
  color: #fff; }

.pos_navigation {
  background: url(/assets/img/post_navi_sepretor.png) repeat-x left bottom; }

.i_print {
  background: url(../images/i_print.png) no-repeat left top; }

\
.video_main {
  border: 2px solid #ccc; }

/* archive page list----------------------- */
#content .news_list li {
  background: url(/assets/img/sepretor.png) no-repeat center bottom;
  position: relative; }

#content .news_list li h3 {
  background: none;
  color: #0d428b; }

#content .news_list li h3 a {
  color: #157198; }

#content .news_list li h3 a:hover {
  color: #000; }

#content .news_list li p.date {
  color: #ccc; }

#content .news_list li p.postedby {
  color: #929292; }

#content .news_list li p.postedby .pcomments {
  background: url(/assets/img/i_comments.png) no-repeat right 4px; }

#content .news_list li p.postedby a.pcomments {
  color: #8a8a8a; }

#content .news_list li p.postedby a {
  color: #4f4f4f; }

#content .news_list li p.postedby a:hover, #content .news_list li p.postedby a:hover.pcomments {
  color: #000; }

#content .news_list li img {
  float: left;
  margin: 5px 15px 0 0;
  padding: 5px;
  border: 1px solid #c9c9c9; }

a.more {
  color: #494949;
  background: #e7e5e5 url(/assets/img/bullet.png) no-repeat 74px 10px; }

a:hover.more {
  color: #157198; }

/* related post ----------------------- */
#content .category_newslist li {
  background: url(/assets/img/hr.png) repeat-y 18px top; }

#content .category_newslist li h3 {
  background: none;
  color: #0d428b;
  border: none; }

#content .category_newslist li h3 a {
  color: #157198; }

#content .category_newslist li h3 a:hover {
  color: #333; }

#content .category_newslist li p.date {
  color: #ccc; }

#content .category_newslist li img {
  border: 1px solid #c9c9c9; }

#content .category_newslist li p.date .pcomments {
  background: url(/assets/img/i_comments.png) no-repeat right 4px; }

#content .category_newslist li p.date a.pcomments {
  color: #8a8a8a; }

#content .category_newslist li p.date a:hover.pcomments {
  color: #000; }

#content .category_newslist li.hr {
  background: url(/assets/img/hr.png) repeat-x center; }

/* featured video */
#sidebar .featured_video {
  color: #d64046;
  border: 1px solid #d0d0d0; }

#sidebar .featured_video h4 a {
  color: #0d428b; }

.advt4widget {
  border: 1px solid #d0d0d0; }

/*---------Top Pages Navigation for PAGES---------  */
#page_nav ul li ul li {
  /* border-style: solid; border-width: 1px; border-color: #ccc #ccc #ccc;*/ }

#page_nav ul li ul li.hover, #page_nav ul li ul li:hover, #page_nav ul li ul li.current-cat:hover, ul.dropdown li ul li.current_page_parent:hover, ul.dropdown li ul li.current_page_item:hover {
  color: #333;
  background: #a0a0a0; }

#page_nav ul li a {
  color: #909090;
  background: url(/assets/img/page_nav_sepretor.png) no-repeat left top; }

#page_nav ul li a:hover, #page_nav li:hover a {
  color: #fff;
  background: #a0a0a0; }

#page_nav li:hover a {
  color: #fff;
  background: #a0a0a0; }

#page_nav li:hover ul li a {
  color: #fff;
  background: #a0a0a0;
  border-left: none;
  border-right: none; }

#page_nav ul li.current_page_item a,
#page_nav ul li.current_page_parent a,
#page_nav ul li.current-menu-item a {
  background: #6d6d6d;
  color: #fff; }

#page_nav ul li.current_page_item ul li a,
#page_nav ul li.current_page_parent ul li a,
#page_nav ul li ul li a {
  background: #a0a0a0;
  color: #fff;
  border-bottom: 1px solid #c6c6c6; }

#page_nav ul li.current_page_item ul li a:hover,
#page_nav ul li.current_page_parent ul li.current_page_item a,
#page_nav ul li.current_page_parent ul li a:hover,
#page_nav ul li ul li a:hover,
#page_nav ul li ul.sub-menu li a,
#page_nav ul li ul.sub-menu ul.sub-menu li a, #page_nav ul li.current_page_parent ul li.current_page_item ul li a:hover {
  background: #6d6d6d;
  color: #fff; }

#page_nav ul li ul li.current_page_parent a {
  background: #6d6d6d; }

#page_nav ul li ul li.current_page_parent ul li.current_page_item a {
  background: #a0a0a0 !important; }

#page_nav ul li ul li.current_page_parent ul li a, #page_nav ul li ul li.current_page_item ul li a {
  background: #a0a0a0 !important; }

#page_nav ul li ul li.current_page_item ul li ul li a:hover {
  background: #6d6d6d !important; }

#page_nav ul li ul.sub-menu li a:hover,
#page_nav ul li ul.sub-menu li.current-menu-item a,
#page_nav ul li ul.sub-menu ul.sub-menu li a:hover,
#page_nav ul li ul.children li.current_page_parent li a {
  background: #6d6d6d;
  color: #fff; }

#page_nav ul ul {
  width: 190px;
  margin-top: 0px;
  border-right: 1px solid #c6c6c6;
  border-left: 1px solid #c6c6c6; }

/*---------Category Navigation for PAGES---------  */
#categories_strip ul li ul li {
  /* border-style: solid; border-width: 1px; border-color: #ccc #ccc #ccc;*/
  background: #126385; }

#categories_strip ul li ul li.hover, #categories_strip ul li ul li:hover, #categories_strip ul li ul li.current-cat:hover, ul.dropdown li ul li.current-cat-parent:hover, ul.dropdown li ul li.current-cat:hover {
  color: #fff;
  background: #02425d; }

#categories_strip ul li a {
  color: #fff;
  background: url(/assets/img/nav_sepretor.png) no-repeat right top;
  border-left: none;
  border-right: none; }

#categories_strip ul li a:hover, #categories_strip li:hover a {
  color: #fff;
  background: #02425d url(/assets/img/current_nav.png) no-repeat right bottom; }

#categories_strip li:hover a {
  color: #fff;
  background: #02425d url(/assets/img/current_nav.png) no-repeat right bottom;
  text-shadow: none; }

#categories_strip li:hover ul li a {
  color: #c8efff;
  background: #02425d;
  border-left: none;
  border-right: none; }

#categories_strip ul li.current-cat a,
#categories_strip ul li.current-cat-parent a,
#categories_strip ul li.current-menu-item a, #categories_strip ul li.current_page_item a {
  background: #02425d url(/assets/img/current_nav.png) no-repeat right bottom;
  color: #fff; }

#categories_strip ul li.current-cat ul li a,
#categories_strip ul li.current-cat-parent ul li a,
#categories_strip ul li ul li a {
  background: #02425d;
  color: #fff;
  border-bottom: 1px solid #126385; }

#categories_strip ul li.current-cat ul li a:hover,
#categories_strip ul li.current-cat-parent ul li.current-cat a,
#categories_strip ul li.current-cat-parent ul li a:hover,
#categories_strip ul li ul li a:hover,
#categories_strip ul li ul.sub-menu li a,
#categories_strip ul li ul.sub-menu ul.sub-menu li a, #categories_strip ul li.current-cat-parent ul li.current-cat ul li a:hover {
  background: #012e41;
  color: #fff; }

#categories_strip ul li ul li.current-cat-parent a {
  background: #02425d; }

#categories_strip ul li ul li.current-cat-parent ul li.current-cat a {
  background: #02425d !important; }

#categories_strip ul li ul li.current-cat-parent ul li a, #categories_strip ul li ul li.current-cat ul li a {
  background: #02425d !important; }

#categories_strip ul li ul li.current-cat ul li ul li a:hover {
  background: #012e41 !important; }

#categories_strip ul li ul.sub-menu li a:hover,
#categories_strip ul li ul.sub-menu li.current-menu-item a,
#categories_strip ul li ul.sub-menu ul.sub-menu li a:hover,
#categories_strip ul li ul.children li.current-cat-parent li a {
  background: #02425d;
  color: #fff; }

#categories_strip ul ul {
  width: 190px;
  margin-top: 0px;
  border-right: 1px solid #126385;
  border-left: 1px solid #126385; }

/*---------ARCHIVE PAGE --------- */
.arclist ul li {
  color: silver;
  border-bottom: 1px solid #f1f1ed;
  background: none; }

.arclist ul li ul {
  border-bottom: 0; }

.arclist ul li ul li {
  border-bottom: 0; }

.arclist .archives-time {
  color: #666666; }

/*---------COMMENTS --------- */
#comments .comment {
  background: none; }

#comments p.authorcomment {
  border-bottom: 1px dotted #ccc; }

#comments p.authorcomment a {
  color: #666; }

#comments p.authorcomment a:hover {
  color: #000; }

#comments .comment .meta-left {
  color: #aaaaaa; }

#comments .comment .authcomment p {
  color: #555; }

#comments .comment .text-right p a {
  color: #157198;
  text-decoration: none; }

#comments .comment .text-right p a:hover {
  color: #000; }

span.comm-reply {
  color: #333;
  text-transform: uppercase; }

span.comm-reply a {
  color: #666; }

span.comm-reply a:hover {
  color: #0c729d; }

.commpadd input {
  background: url(/assets/img/textfieldbg.png) no-repeat left top;
  border: none; }

#respond textarea {
  background: url(/assets/img/textareabg.png) no-repeat left top;
  border: none; }

input#submit {
  color: #fff;
  text-shadow: 1px 1px 1px #333;
  background: #0C729D;
  border: none; }

input#submit:hover {
  background: #000;
  border: none; }

.avatar {
  background: #ddd; }

#comments .comment a.comment-reply-link {
  background: #0c729d url(/assets/img/reply.png) no-repeat 42px;
  color: #fff; }

#comments .comment a:hover.comment-reply-link {
  color: #fff;
  background: #000 url(/assets/img/reply.png) no-repeat 42px; }

#respond .author {
  background: url(/assets/img/i_user.png) no-repeat left top; }

#respond .email {
  background: url(/assets/img/i_email_c.png) no-repeat left top; }

#respond .website {
  background: url(/assets/img/i_website.png) no-repeat left top; }

#respond .icomment {
  background: url(/assets/img/i_comment.png) no-repeat left top; }

#respond .comment_arrow {
  width: 14px;
  height: 17px;
  background: url(/assets/img/comment_arrow.png) no-repeat left top;
  display: block;
  position: absolute;
  left: 36px;
  top: 8px; }

/*#sidebar ----------------------- */
.sidebar .search_sidebar h3 {
  color: #fff;
  background: #37809f url(/assets/img/search_titlebg.png) repeat-x left bottom;
  text-shadow: 1px 1px 1px #333333; }

.sidebar .search_sidebar {
  background: #0c729d url(/assets/img/search_bottombg.png) no-repeat left bottom; }

.sidebar .search_sidebar select {
  background: #fff;
  border: none;
  color: #393939; }

.b_searchcar {
  color: #fff;
  text-shadow: 1px 1px 1px #333;
  background: url(/assets/img/b_searchcar.png) no-repeat left top;
  border: none; }

.b_searchcar:hover {
  background: url(/assets/img/b_searchcar.png) no-repeat 0 -45px; }

/* widget ------ */
.sidebar .widget {
  border: 1px solid #dfdfdf;
  background: #fff url(/assets/img/widgetbg.png) repeat-x left bottom; }

.sidebar .widget h3 {
  color: #fff;
  text-shadow: 1px 1px 1px #333;
  background: #1d7fa8 url(/assets/img/sidebar_titlebg.png) repeat-x left top; }

.sidebar .widget li a {
  color: #626262;
  background: url(/assets/img/bullet.png) no-repeat 10px 10px;
  border-bottom: 1px solid #dfdfdf; }

.sidebar .widget li a:hover {
  color: #157198; }

.sidebar .widget ul li:last-child a {
  border-bottom: none; }

.sidebar .widget p {
  color: #444; }

.sidebar #twitter_update_list li {
  background: url(/assets/img/bullet.png) no-repeat 10px 3px; }

.sidebar #twitter_update_list li a {
  border-bottom: none;
  background: none;
  color: #000; }

.sidebar #twitter_update_list li a:hover {
  color: #157198; }

.sidebar #twitter_update_list li, .sidebar .popular_post li {
  border-bottom: 1px solid #e5e5e5;
  color: #555; }

.sidebar #twitter_update_list li:last-child, .sidebar .popular_post li:last-child {
  border-bottom: none; }

.sidebar #twitter p {
  color: #626262;
  background: url(/assets/img/i_twitter.png) no-repeat 90px top; }

.sidebar #twitter p a {
  color: #0C729D; }

.sidebar #twitter p a:hover {
  color: #000; }

.subscribe_form {
  background: url(/assets/img/subscribebg.png) no-repeat left 1px; }

.subscribe_form .field {
  background: none;
  border: none;
  font: 13px Arial, Helvetica, sans-serif;
  color: #626262; }

.subscribe_form .btn_submit {
  border: none;
  background: url(/assets/img/i_email.png) no-repeat left top; }

.bookmark {
  border: 1px solid #dfdfdf;
  background: url(/assets/img/widgetbg.png) repeat-x left bottom; }

/* footer ---------------------------- */
#footer p {
  color: #9e9e9e; }

#footer p span.templatic {
  color: #fff;
  background: url(../images/templatic.png) no-repeat 0; }

#footer a {
  color: #ccc;
  text-decoration: underline; }

#footer a:hover {
  color: #fff; }

#footer p {
  color: #929292; }

#footer .copyright, #footer .copyright a {
  color: #929292; }

#footer p a:hover {
  color: #fff; }

/*
File:			custom.css
Description:	Custom styles for Your Theme
*/
/* APK.bar */
.apkbar {
  background-color: red;
  color: white;
  font-weight: bold;
  font-size: 2em;
  padding: 15px;
  text-align: center;
  padding-bottom: 20px;
  /*border-radius: 10px;*/
  -webkit-border-top-left-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-topright: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  /*transform: rotate(25deg) translate(50px,119px);
  -ms-transform: rotate(25deg) translate(50px,119px);
  -webkit-transform:rotate(25deg) translate(50px,119px);
  margin-bottom: -150px;*/ }

.apklist {
  /*transform: translate(50px,350px);
 -ms-transform: translate(50px,350px);
 -webkit-transform:translate(50px,350px);*/
  border: 1px solid red;
  border-top: none;
  padding: 10px; }

.apklist li::before {
  content: "• ";
  color: red; }

/* Home page featured slider
-----------------------------------------------------------*/
#slidercontent {
  margin-left: 15px; }

#featured-slider {
  float: left;
  width: 650px;
  height: 430px;
  margin: 0 10px;
  position: relative; }

#featured-slider #slides {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 100; }

#featured-slider .slides_container {
  width: 650px;
  overflow: hidden;
  position: relative;
  display: none;
  background: #FFF; }

#featured-slider .slides_container div.slide {
  width: 650px;
  height: 430px;
  display: block; }

#featured-slider .caption {
  z-index: 500;
  position: absolute;
  top: 0px;
  height: 220px;
  width: 6500px;
  font-size: 12px;
  line-height: 16px;
  color: #000; }

#featured-slider .caption h2 {
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 10px; }

#featured-slider #slides .next, #slides .prev {
  position: absolute;
  top: 212px;
  left: -19px;
  width: 30px;
  height: 37px;
  display: block;
  z-index: 101;
  text-indent: -99999em;
  background: url(images/larr.gif); }

#featured-slider #slides .next {
  left: 640px;
  background: url(images/rarr.gif); }

div.transbox {
  width: 750px;
  height: 20px;
  background-color: #ccc;
  /*border:1px solid black;*/
  opacity: 0.6;
  filter: alpha(opacity=60);
  /* For IE8 and earlier */
  padding: 7px;
  position: absolute;
  bottom: 0px; }

div.transbox p {
  font-weight: bold;
  color: #fff; }

.slidertitle {
  width: 650px;
  height: 22px;
  font-size: 2em;
  margin-top: 0px;
  background-color: #ccc;
  /*border:1px solid black;*/
  opacity: 0.6;
  filter: alpha(opacity=60);
  /* For IE8 and earlier */
  padding: 15px;
  text-align: center; }

.slidertitle p {
  color: white;
  font-weight: bold; }

.sliderprice {
  position: absolute;
  font-size: 3em;
  padding: 15px;
  bottom: 30px;
  right: 0px; }

.sliderprice p {
  color: #ddd; }

.thick {
  color: #fff;
  background: #02425d url(/assets/img/current_nav.png) no-repeat right bottom; }
